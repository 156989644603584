<template>

  <!-- Navbar -->
  <div id="header" class="stickyHeader">
    <Navbar 
      v-if="!isMobile"
      @changedProductMenuStateOpen="handleProductMenuOpened"
      @changedProductMenuStateClosed="handleProductMenuClosed"
    />
    <MobileNavbar
      v-if="isMobile"
      @changedMobileMenuStateOpen="handleProductMenuOpened"
      @changedMobileMenuStateClosed="handleProductMenuClosed"
    >
    </MobileNavbar>
  </div>
  <div class="content" 
      :class="{ layer: productMenuIsActive }">
    <!-- rendered views -->

    <router-view/>
    
    <Footer />
  </div>
  

</template>

<script>

import Navbar from "@/components/Navbars/AppNavbar.vue";
import MobileNavbar from "@/components/Navbars/AppMobileNavbar.vue";
import Footer from "@/components/AppFooter.vue";

export default {
  name: 'App',
  components: {
    Navbar,
    MobileNavbar,
    Footer
  },
  data() {
    return {
      productMenuIsActive: false,
      isMobile: false
    }
  },
  beforeMount() {
    this.checkMobile();
  },
  methods: {
    handleProductMenuOpened() {
      this.productMenuIsActive = true
      this.changeZIndex(-1)
    },
    handleProductMenuClosed() {
      this.productMenuIsActive = false
      
      this.changeZIndex('auto')
    },
    changeZIndex(index) {
      var cols = document.getElementsByClassName('gridRow');
      for(var i = 0; i < cols.length; i++) {
        cols[i].style.zIndex = index
      }
    },
    checkMobile() {
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        this.isMobile = true;
      }
    }
  }
}
</script>

<style>
  @import '@/assets/fontawesome/css/fontawesome.css';
  @import '@/assets/fontawesome/css/brands.css';
  @import '@/assets/fontawesome/css/solid.css';

  :root {
    --cilab-blue: #005ca9;
    --cilab-blue-rgb: 0, 92, 169;
    --cilab-grey: #878787;
    --cilab-grey-rgb: 135, 135, 135;
    --header-padding-offset: 83px;
  }

  #app {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    position: relative;
  }

  body {
    margin: 0px;
    height: 100%;
  }

  #header {
    z-index: 3;
    top: 0;
    position: fixed;
    width: 100%;
  }

  .stickyHeader + .content {
    padding-top: var(--header-padding-offset)
  }

  .content {
    padding-bottom: 248px;
    min-height: 80vh;
  }

  @media screen and (max-width: 1024px) {
    .content {
      padding-bottom: 0;
    }
  }

  .layer {
    background-color: rgba(var(--cilab-grey-rgb), 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .4s;
  }

  /* Layout stuff */
  h1 {
    margin: 15px 0 15px 0;
  }

  .gridHeading {
    text-align: center;
    padding-top: 10px;
    width: 30%;
    margin-left: 35%;
  }

  .gridHeading::after {
    display: block;
    position: relative;
    content: '';
    height: 4px; 
    margin: 0 auto;
		left: 0;
    right: 0;
		width: 25%;
		background: var(--cilab-blue);
  }

  .gridRow {
    display: grid;
    width: 75%;
    margin-left: 12.5%;
    margin-top: 2%;
    margin-bottom: 2%;
    position: relative;
  }

  .col-md-0 {
    grid-template-columns: 100%;
    width: 80%;
    margin-left: 10%;
  }

  .col-md-1 {
    grid-template-columns: 100%;
    width: 70%;
    margin-left: 15%;
	margin-top: 1%;
  	margin-bottom: 1%;
  }

  .col-md-2 {
    grid-template-columns: repeat(2, 1fr);
	gap: 2.5rem;
  }

  .col-md-3 {
    grid-template-columns: 33.333% 33.333% 33.333%;
  }

  @media screen and (max-width: 1024px) {
    .gridHeading {
      width: 100%;
      margin: 0;
    }
    .gridRow {
      display: grid;
      width: 95%;
      margin-left: 2.5%;
    }
    .col-md-2 {
      grid-template-columns: 100%;
    }
    .col-md-3 {
      grid-template-columns: 100%;
    }
  }

  .gridBox {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }

  .bold {
    font-weight: bold;
  }
</style>
