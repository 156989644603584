<template>
    <div class="banner-background gridRow">
        <div class="gridRow col-md-2">
            <div class="gridBox bannerBox">
                <img class="trustech-banner" src="../../assets/banners/trustech-2023.webp" alt="">
            </div>
            <div class="gridBox trustech-location-text">
                <p class="trustech-heading">We are on Trustech 2023</p>
                <p><i class="fa fa-map-marker-alt trustech-location"></i> Find us at <span class="bold">Pavillon 5.2 D 031</span></p>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
  
}
</script>

<style scoped>
.banner-background {
    width: 100%;
    background-color: rgb(22, 37, 72) !important;
    color: white;
    margin: 0;
}

.gridRow {
    margin-top: 0;
    margin-bottom: 0;
}

.bannerBox {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trustech-heading {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.375rem;
}

.trustech-location {
    padding: 5px;
}

.trustech-banner {
    width: 295px;
    height: auto;
}

.trustech-location-text {
    text-align: left;
    padding: 10px;
}

@media screen and (max-width: 700px) {
    .trustech-location-text {
        text-align: center;
    }
}

</style>
